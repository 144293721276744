export enum StatusRequest {  
  REQUEST_EN_GESTION = '13A5822B-BA45-466C-8790-A394FE75C2A0',
  REQUEST_EN_REVISION_VISITA = '7DE015FA-A6AB-425D-8353-0C5C78B8E71B',
  REQUEST_DEVUELTO_POR_COORDINADOR = 'F4F7FF3B-66AC-4FB1-91E8-2530226A009C',
  REQUEST_PARA_FIRMAR_PROGRAMAR_VISITA = '335205B8-2ED9-49EB-8EC6-F907100FC73B',
  REQUEST_DEVUELTO_POR_SUBDIRECTOR = '07D79311-27B1-4548-A680-A262C0E39A65',
  REQUEST_PARA_VISITA = '8E6B552E-0187-408D-A50E-8476B0D13E5F',

  REQUEST_REVISION_OTORGAMIENTO_RES = '2416BCFA-A361-43A7-B254-4A213A134F2C',
  REQUEST_REVISION_NEGACION_RES = 'AF5F96D0-483C-4D2D-8430-914D5AFACF7A',
  REQUEST_PARA_FIRMAR_APROBACION_RES = '6D55C33C-1973-496A-86DE-8AE47A29C7C0',
  REQUEST_PARA_FIRMAR_NEGACION_RES = '1F52E162-1F56-4716-AD21-C4CEA7F21F63',
  REQUEST_DEVUELTO_POR_COORDINADOR_RES = '47022FD1-0BCA-4A92-A4C3-784582DC1BC7',
  REQUEST_RESOLUCION_APROBADA ='3C7A88EB-E7CC-493E-8CFD-59A07AA9D4AF',
  REQUEST_RESOLUCION_NEGADA = 'B1755627-6C9C-4056-B38D-9266EF5AE87E',
  REQUEST_DEVUELTO_POR_SUBDIRECTOR_RES = '9BF1C8F4-6091-477D-9587-162B172AF643',

  REQUEST_EN_REVISION_AJUSTE_DOC = 'D81939D3-5612-45D6-95D8-2237554F8C20',
  REQUEST_PARA_FIRMAR_PROGRAMAR_VISITA_MAS_AJUSTE_DOC = 'D576D69D-B5C3-4AF4-B285-EB30DCF49EAC',
  REQUEST_PARA_VISITA_AJUSTE_DOC = 'C379DF0B-F70B-4FE2-A940-09062C973A29',
  REQUEST_SUBSANADA_AJUSTE_DOC = '2915F385-F1DA-483B-AA1C-D821A77CBBC6',




/*ciudadano = En subsanacion
  validador = En gestion
  Coordinador = En validacion
  Subdirector = En firma*/
  
  
  
  REQUEST_ANULADA = '96716074-A0C0-41C5-88F7-1EE478A88656',
    
  REQUEST_REVISION_ANULACION = '5ABB1F5B-78DC-42CD-A10A-6B7376C36E55'
  
}
