import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: [ './accesibility.component.scss' ]
})
export class AccesibilityComponent implements OnInit {
  font: number = 16;
  colorBG: any;
  activeContrast: any = 0;

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,private router: Router) {
  }

  ngOnInit(): void {
    this.moveContrast();
    this.router.events.subscribe((event: any) => {


      if (event instanceof NavigationEnd) {
        let selectores:any[]=[]
        this.font=16;

        selectores.push(document.querySelectorAll('html'));
        selectores.push(document.querySelectorAll('div'));
        selectores.push(document.querySelectorAll('a'));
        selectores.push(document.querySelectorAll('span'));
        selectores.push(document.querySelectorAll('p'));
        selectores.push(document.querySelectorAll('input'));
        selectores.push(document.querySelectorAll('button'));
        selectores.push(document.querySelectorAll('label'));
        selectores.push(document.querySelectorAll('h1'));
        selectores.push(document.querySelectorAll('h2'));
        selectores.push(document.querySelectorAll('h3'));
        selectores.push(document.querySelectorAll('h4'));
        selectores.push(document.querySelectorAll('h5'));

        setTimeout(() => {
          for (let elements of selectores) {
            for (let element of elements) {
              element.style.fontSize = this.font + "px";
            }
          }

        }, 200);

      }



    });
  }

  up() {
    this.font = this.font === 100 ? this.font : this.font + 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  down() {
    this.font = this.font === 1 ? this.font : this.font - 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  moveContrast() {

    if (this.activeContrast == 1) {
      this.activeContrast = false;

      setTimeout(() => {
        this.renderer.addClass(this.document.body, 'body-cs');

      }, 200);
    } else {
      this.activeContrast = true;

      setTimeout(() => {
        this.renderer.removeClass(this.document.body, 'body-cs');

      }, 200);
    }
  }

}
