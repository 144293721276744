import { Component, HostListener } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  constructor() {
    // This is intentional
  }
  isLoading: boolean = false;

  @HostListener("window:storage", ["$event"])
  ListenForIsLoading(e: StorageEvent) {
    if (e.key === "IsLoading") {
      this.isLoading = e.newValue == "true";
    }
  }
}
