import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public showLoader() {
    localStorage.setItem("IsLoading", "true");
    const event = new StorageEvent("storage", {
      key: "IsLoading",
      newValue: "true",
    });

    window.dispatchEvent(event);
  }
  public hideLoader() {
    localStorage.setItem("IsLoading", "false");
    const event = new StorageEvent("storage", {
      key: "IsLoading",
      newValue: "false",
    });

    window.dispatchEvent(event);
  }
}
