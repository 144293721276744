import { Component, Inject, OnInit } from '@angular/core';
import { SSOAuthResponse } from "@models";
import { Rol, ROUTES_PATH } from "@enums";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { AuthService, RequestService } from "@services";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import { InteractionStatus } from "@azure/msal-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {

  local;
  opacity;
  currentUser!: SSOAuthResponse;
  routes = ROUTES_PATH;
  amountOfNotification = 5;
  public isLogueado = false;
  currentRol = Rol

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(private router: Router,
              @Inject(Window) private _window: Window,
              private authServiceOLD: AuthService, private requestService: RequestService,
              private authService: MsalService, private broadcastService: MsalBroadcastService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authServiceOLD.currentUserValue;

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

    this.authServiceOLD.currentUser.subscribe((res) => this.currentUser = res)
  }

  setLogout = (): void => {
    localStorage.clear();
    if (this.loginDisplay) {
      localStorage.removeItem('state')
      localStorage.removeItem('currentUser')
      localStorage.removeItem('tamrequest')
      localStorage.removeItem('dateIn')
      localStorage.removeItem('oneError')
      const logoutRedirect = `${ this._window.location.origin }/sign-in`;
      this.authService.logoutRedirect({
        postLogoutRedirectUri: logoutRedirect
      });
    } else {
      const payload = {
        tokenValue: this.currentUser?.refresh_token,
        userID: this.currentUser?.userId
      };

      this.authServiceOLD.internalLogout(payload)
        .subscribe(res => {
          localStorage.removeItem('state')
          localStorage.removeItem('currentUser')
          localStorage.removeItem('tamrequest')
          localStorage.removeItem('dateIn')
          localStorage.removeItem('oneError')
          this.authServiceOLD.currentUserSubject.next(null);
          setTimeout(() => {
            this.router.navigateByUrl(ROUTES_PATH.SignIn);
          }, 1000)
        }, error => {
          alert('Vuelva a intentarlo. Disculpe las molestias.');
        })
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
