<div class="d-flex flex-column general-layout">
  <div style="margin: 2% 0 1% 0;">
    <app-header></app-header>
  </div>
  <div class="content-layout" id="content-layout-id">
    <main class="scroll" id="interno">
      <div class="information d-flex justify-content-center">

        <div class="govco-form-signin col-lg-6 col-md-6 col-12 my-4">
          <form [formGroup]="loginForm">
            <h3 class="govco-title-sign-form headline-l-govco" style="text-align: center;">
              Inicio de sesión
            </h3>
            <div class="d-flex" style="display: flex; align-items: center; justify-content: center;">
              <button type="submit"
                      (click)="login()"
                      class="btn btn-round btn-high mx-0">INICIAR SESIÓN</button>
            </div>
          </form>

        </div>
      </div>
      <div class="linea">
        <app-footer></app-footer>
      </div>
    </main>
  </div>
</div>

