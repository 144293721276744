import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  getCodeVentanillaByIdUser(idToken) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetCodeVentanillaByIdUser/${idToken}`);
  }

  getRoleByIdUser(oid: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetRoleByIdUser/${oid}`);
  }

}
