import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Request } from '@models';
import { BehaviorSubject, lastValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import moment from 'moment';
import { SaveScientificDirectorDTO } from '../models/saveScientificDirectorDTO.model';
import { SaveLocationDTO } from '../models/saveLocationDTO.model';
import { SaveRequestDTO } from '../models/saveRequestDTO.model';
import { StatusRequest } from '../enums/statusRequest.enum';
import { TramiteSharedService } from './tramite-shared.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  typeRequest = new BehaviorSubject(null);
  typeRequest$ = this.typeRequest.asObservable();

  requestNumRadicado = new BehaviorSubject(null);
  requestNumRadicado$ = this.requestNumRadicado.asObservable();

  requestSelected = new BehaviorSubject(null);
  requestSelected$ = this.requestSelected.asObservable();

  getRequest = new BehaviorSubject(false);
  getRequest$ = this.getRequest.asObservable();

  viewRequest = new BehaviorSubject(false);
  viewRequest$ = this.viewRequest.asObservable();

  AssemblyMinutes = new BehaviorSubject(null);
  AssemblyMinutes$ = this.AssemblyMinutes.asObservable();

  ResponseResolution = new BehaviorSubject(null);
  ResponseResolution$ = this.ResponseResolution.asObservable();

  resetRequest: Subject<boolean> = new ReplaySubject<boolean>(1)
  resetRequest$ = this.resetRequest.asObservable();

  VisitSchedule = new BehaviorSubject(null);
  VisitSchedule$ = this.VisitSchedule.asObservable();

  VisitScheduleOne = new BehaviorSubject(null);
  VisitScheduleOne$ = this.VisitScheduleOne.asObservable();



  constructor(private http: HttpClient,
              private serviceShared : TramiteSharedService) {
  }

  saveScientificDirector(req: SaveScientificDirectorDTO): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/v1/ScientificDirector/SaveScientificDirector`, req);
  }

  saveLocation(req: SaveLocationDTO): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/v1/Location/SaveLocation`, req);
  }

  saveRequest(json: SaveRequestDTO): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/v1/Request/SaveRequest`, json);
  }

  /*getRequestsByIdUserExternal(idUserExternal : string, search : string, page : string): Observable<any> {
    let url = `${idUserExternal}?${(search != null && search != '') ? `search=${search}` : ''}&page=${page}&pageSize=10`
    return this.http.get<any>(`${ environment.apiUrlBack }/api/v1/Request/GetRequestsByIdUserExternal/${url}`).pipe(
      map((data: any) => {
        data.data.requests = data.data.requests.map(item => ({
          ...item,
          statusApplicant: this.convertirEstado(item.stateRequest.value)
        }));
        return data;
      })
    );
  }*/

  getRequestsByIdUserExternal(idUserExternal : string, search : string, page : string, pageSize : string): Observable<any> {
    let url = `${idUserExternal}?${(search != null && search != '') ? `search=${search}` : ''}&page=${page}&pageSize=${pageSize}`
    return this.http.get<any>(`${ environment.apiUrlBack }/api/v1/Request/GetRequestsByIdUserExternal/${url}`).pipe(
      map((data: any) => {
        data.data.requests = data.data.requests.map(async item => {
          const fechaSolicitud = moment(item.dateRequest).format('YYYY-MM-DD');
          let resp = await lastValueFrom(this.serviceShared.calculateRemainingDays(fechaSolicitud, environment.idProcedureDBCommmons));
          let diasDeAtencionRestante = resp.data.personaJuridica.diasRestantes;
          let diasHabilesTranscurridos = resp.data.personaJuridica.diasHabilesTanscurridos;
          return {
            ...item,
            diasDeAtencionRestante: diasDeAtencionRestante < 0 ? 0 : diasDeAtencionRestante,
            diasHabilesTranscurridos: diasHabilesTranscurridos,
            flag: this.getColorFlag(diasHabilesTranscurridos),
            statusApplicant: this.convertirEstado(item.stateRequest.value)
          }
        });
        return data;
      })
    );
  }


  convertirEstado(idEstado : string){
    let statusApplicant = "";

    switch(idEstado.toUpperCase()){
      case StatusRequest.REQUEST_RESOLUCION_APROBADA :
        statusApplicant = 'Aprobada';
        break;
      case StatusRequest.REQUEST_RESOLUCION_NEGADA :
        statusApplicant = 'Negada';
        break;
      case StatusRequest.REQUEST_PARA_VISITA_AJUSTE_DOC :
        statusApplicant = 'Para visita';
        break;
      case StatusRequest.REQUEST_EN_GESTION :
      case StatusRequest.REQUEST_DEVUELTO_POR_COORDINADOR :
      case StatusRequest.REQUEST_PARA_VISITA :
      case StatusRequest.REQUEST_DEVUELTO_POR_COORDINADOR_RES :
      case StatusRequest.REQUEST_SUBSANADA_AJUSTE_DOC :
        statusApplicant = 'En gestión';
        break;
      case StatusRequest.REQUEST_EN_REVISION_VISITA :
      case StatusRequest.REQUEST_DEVUELTO_POR_SUBDIRECTOR :
      case StatusRequest.REQUEST_REVISION_OTORGAMIENTO_RES :
      case StatusRequest.REQUEST_REVISION_NEGACION_RES :
      case StatusRequest.REQUEST_DEVUELTO_POR_SUBDIRECTOR_RES :
      case StatusRequest.REQUEST_EN_REVISION_AJUSTE_DOC :
        statusApplicant = 'En validación';
        break;
      case StatusRequest.REQUEST_PARA_FIRMAR_PROGRAMAR_VISITA :
      case StatusRequest.REQUEST_PARA_FIRMAR_APROBACION_RES :
      case StatusRequest.REQUEST_PARA_FIRMAR_NEGACION_RES :
      case StatusRequest.REQUEST_PARA_FIRMAR_PROGRAMAR_VISITA_MAS_AJUSTE_DOC :
        statusApplicant = 'En firma';
        break;
    }
    return statusApplicant;
  }
  /**
   * Obtiene la informacion de una solicitud especifica
   */
  getRequestById(id): Observable<Request> {
    return this.http.get<any>(`${ environment.apiUrlBack }/api/v1/Request/GetRequestByIdRequest/${ id }`).pipe(map(resp => {
      return resp.data;
    }));
  }


  /**
   * Obtiene las solicitudes por rol
   */
  getRequestsByRolDisp(idrol: any,search:string,filter:string,pagenumber:any,pagesise:any): Observable<any> {
    let url = `${idrol}?search=${search}&filter=${filter}&page=${pagenumber}&pageSize=${pagesise}`;
    return this.http.get<any>(`${ environment.apiUrlBack }/api/v1/Request/GetRequestsByIdRol/${url}`).pipe(
      map((data: any) => {
        data.data.requests = data.data.requests.map(async item => {
          const fechaSolicitud = moment(item.dateRequest).format('YYYY-MM-DD');
          let resp = await lastValueFrom(this.serviceShared.calculateRemainingDays(fechaSolicitud, environment.idProcedureDBCommmons));
          let diasDeAtencionRestante = resp.data.personaJuridica.diasRestantes;
          let diasHabilesTranscurridos = resp.data.personaJuridica.diasHabilesTanscurridos;
          return {
            ...item,
            diasDeAtencionRestante: diasDeAtencionRestante < 0 ? 0 : diasDeAtencionRestante,
            diasHabilesTranscurridos: diasHabilesTranscurridos,
            flag: this.getColorFlag(diasHabilesTranscurridos)
          }
        });
        return data;
      })
    );
  }

  getColorFlag(diasHabilesTranscurridos : number){
    if(diasHabilesTranscurridos < 26){
      return "green";
    }else if(diasHabilesTranscurridos < 41) {
      return "yellow";
    }else{
      return "red";
    }
  }

  /**
   * Actualiza los datos de la solicitud
   */
  updateRequest(json:any): Observable<any> {
    return this.http.patch(`${ environment.apiUrlBack }/api/v1/Request/UpdateRequest`,json);
  }
  /**
   * Actualiza el estado de la solicitud
   */
  updateStateRequest(json:any): Observable<any> {
    return this.http.patch(`${ environment.apiUrlBack }/api/v1/Request/UpdateStateRequest`,json);
  }

  /**
   * Actualiza los datos de la solicitud
   */
  saveRequestHistory(json:any): Observable<any> {
    return this.http.post(`${ environment.apiUrlBack }/api/v1/RequestHistory/SaveRequestHistory`,json);
  }
  /**
   * Actualiza los datos de la solicitud
   */
  saveVisit(json:any): Observable<any> {
    return this.http.post(`${ environment.apiUrlBack }/api/v1/Visit/SaveVisit`,json);
  }

  /**
   * guarda la  osbservacion de negacion
   */
  saveNegationObservation(json:any): Observable<any> {
    return this.http.patch(`${ environment.apiUrlBack }/api/v1/Request/SaveNegationObservation`,json);
  }
  /**
   * Obtiene el seguimiento de una solicitud dado su idrequest
   */
  public getTracking(idrequest: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlBack }/api/v1/RequestHistory/GetRequestHistoryByIdRequest/${idrequest}`);
  }
  /**
   * Actualiza el usuario de una solicitud
   */
  updateUserInternal(json: any): Observable<any> {
    return this.http.patch<any>(`${ environment.apiUrlBack }/api/v1/Request/UpdateUserInternal`, json);
  }

  /**
   * Obtiene los datos del reporte
   */
  getReport(fechainicio:string,fechafin:string,solicitud: any,estado:string,persona:any,resolucion:any,page:any,pagesize:any,numeroid:any): Observable<any> {
    let estadobd= (estado!="" && estado!=null) ? `&StateRequest=${estado}`: "";
    let personabd= (persona!=""&& persona!=null) ? `&TypePerson=${persona}`: "";
    let url = `?InitialDate=${fechainicio}&FinalDate=${fechafin}&Radicado=${solicitud}${estadobd}${personabd}&ResolutionNumber=${resolucion}&Numeroid=${numeroid}&page=${page}&pageSize=${pagesize}`;
    return this.http.get(`${ environment.apiUrlBack }/api/v1/Report/GetReport${url}`);
  }


  /**
   * Obtiene el rol de un usuario dado su oid del b2c
   * @param oid Id dado por el b2c del usuario a buscar
   */
  getRoleByIdUser(oid: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetRoleByIdUser/${oid}`);
  }
  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  public getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${environment.TRAMITE_SHARED_URL}/v2/Persona/GetInfoUserById/${idCode}`);
  }
  /**
   * Obtiene codigo de ventanilla de un usuario dado su oid del B2C
   * @param oidToken_usuario Id dado por el b2c
   */
  public getCodeVentanillaByIdUser(oidToken_usuario: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetCodeVentanillaByIdUser/${oidToken_usuario}`);
  }
  /**
   * Obtiene lista de Tipos de Identificacion
   */
  public getIdentificationType(): Observable<any>{
    return this.http.get(`${environment.TRAMITE_SHARED_URL}/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }


}
