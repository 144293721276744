export enum ROUTES_PATH {
  SignIn = 'sign-in',
  ValidatorsDashboard = 'Dashboard',

  ValidatorsScreen = 'validation',

  ReporstDashboard = 'reports',
  AddRequestProcedure = 'procedure/request/add',
  ResumeRequest = 'procedure/request/resumeRequest/',
  SearchRequest = 'procedure/request/add/searchRequest',
  ValidationRequestProcedure = 'procedure/request/validation',
  CoordinatorRequestProcedure = 'procedure/request/coordinator',
  SubdirectorRequestProcedure = 'procedure/request/subdirector',

}
