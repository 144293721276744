import { Component } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import { InteractionStatus } from "@azure/msal-browser";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
  title = 'Secretaría de Salud Bogotá';
  json;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(// private nomenclatorService: NomenclatorService,
              // private store: Store<GlobalState>,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('state')) {
      this.json = JSON.parse(localStorage.getItem('state'));
    }

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
